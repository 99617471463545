<template>
	<div class="uu-category">
		<div class="uu-decorate-menu">
			<el-menu :default-active="activeIndex">
				<template v-for="(item,index) in menuList">
					<router-link :to="item.path" :key="index" v-if="!item.children">
						<el-menu-item  :index="item.name">{{item.name}}</el-menu-item>
					</router-link>
					<el-submenu :key="index" :index="item.name" v-else>
						<template slot="title">{{item.name}}</template>
						<template v-for="(child,cindex) in item.children">
							<el-menu-item :key="cindex" :index="child.name" v-if="!child.children">{{child.name}}</el-menu-item>
							<el-submenu :key="cindex" :index="child.name" v-else>
								<template slot="title">{{child.name}}</template>
								<el-menu-item v-for="(child2,cindex2) in child.children" :key="cindex2" :index="child2.name">{{child2.name}}</el-menu-item>
							</el-submenu>
						</template>
					</el-submenu>
				</template>
			</el-menu>
		</div>
		<div class="uu-decorate-main">
			<div class="uu-decorate-toolbar">
				<div class="toolbar-action">
					<el-button @click="handleAppCate()" size="mini"><i class="el-icon-plus"></i>{{$t('seller.actions.addManualCate')}}</el-button>
					<!-- <el-button size="mini"><i class="el-icon-plus"></i>{{$t('seller.actions.addAutoCate')}}</el-button> -->
				</div>
				<div class="toolbar-opts">
					<!-- <a class="btn-help" href="javscript:void(0);"><i class="el-icon-question"></i>{{($t'seller.actions.useHelp')}}</a> -->
					<el-button @click="handleSaveCate" size="mini">{{$t('seller.actions.saveChange')}}</el-button>
				</div>
			</div>
			<div class="uu-decorate-panel">
				<div class="uu-decorate-panel-head">
					<span style="margin-right: 10px;"><el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange">{{$t('seller.checkbox.checkAll')}}</el-checkbox></span>
					<el-button @click="handleDeleteCate" size="mini">{{$t('seller.actions.batchDelete')}}</el-button>
					<!-- <div class="toolbar-opts">
						<span>{{$t('seller.actions.expand')}}</span> |
						<span>{{$t('seller.actions.collapse')}}</span>
					</div> -->
				</div>
				<div class="uu-decorate-panel-main">
					<el-scrollbar>
						<table class="uu-decorate-panel-table">
							<thead>
								<tr>
									<th width="3%"></th>
									<th width="2%"></th>
									<th width="19%">{{$t('seller.tableHead.cateName')}}</th>
									<th width="15%">{{$t('seller.tableHead.cateImg')}}</th>
									<th width="15%">{{$t('seller.tableHead.move')}}</th>
									<th width="12%">{{$t('seller.tableHead.defaultOpen')}}</th>
									<th width="10%">{{$t('seller.tableHead.createTime')}}</th>
									<th width="12%">{{$t('seller.tableHead.cateType')}}</th>
									<th>{{$t('seller.tableHead.handle')}}</th>
								</tr>
							</thead>
							<template v-for="(item,index) in cateList">
								<tbody :key="index" :class="item.default_open==1?'expand':'folding'" v-if="item.if_show == 1">
									<tr class="cat-sup">
										<td><el-checkbox v-model="checkCate" :label="index" @change="handleChangeCate($event,index)">&nbsp;</el-checkbox></td>
										<td><i class="el-icon-caret-right" @click="handleOpen($event)"></i></td>
										<td><el-input v-model="item.cate_name" maxlength="20" size="mini"></el-input></td>
										<td>
											<template v-if="item.image_url">
												<el-popover
													placement="top-start"
													width="200"
													trigger="hover">
													<img :src="item.image_url" @error="imageLoadError" width="100%">
													<a class="table-icon" slot="reference" @click="handleUploadShow(index)"><i class="uu-cate-icon J_EditPic"></i>{{$t('seller.actions.edit')}}</a>
												</el-popover>
												<a class="table-icon" @click="handleImageDel(index)">{{$t('seller.actions.delete')}}</a>
											</template>
											<template v-else>
												<a class="table-icon" slot="reference" @click="handleUploadShow(index)"><i class="uu-cate-icon J_AddPic"></i>{{$t('seller.actions.addImage')}}</a>
											</template>
										</td>
										<td>
											<a href="javascript:void(0);" class="uu-cate-icon J_CatMoveTop" @click="handleMoveTop(index)"></a>
											<a href="javascript:void(0);" class="uu-cate-icon J_CatMoveUp" @click="handleMoveUp(index)"></a>
											<a href="javascript:void(0);" class="uu-cate-icon J_CatMoveDown" @click="handleMoveDown(index)"></a>
											<a href="javascript:void(0);" class="uu-cate-icon J_CatMoveBtm" @click="handleMoveBtm(index)"></a>
										</td>
										<td>
											<el-switch
												v-model="item.default_open"
												:active-value="1"
												:inactive-value="0">
											</el-switch>
										</td>
										<td>{{item.create_time | parseTime('{y}-{m}-{d}')}}</td>
										<td>{{item.type==1?$t('seller.decorate.manualCate'):$t('seller.decorate.autoCate')}}</td>
										<td>
											<a href="javascript:void(0);" class="table-btns" @click="handleRemoveCate(index)">{{$t('seller.actions.delete')}}</a>
											<!-- <a href="javascript:void(0);" class="table-btns">{{$t('seller.actions.view')}}</a> -->
										</td>
									</tr>
									<template v-for="(child,cindex) in item.children">
										<tr class="cat-sub" :key="cindex" v-if="child.if_show == 1">
											<td><el-checkbox v-model="checkCate" :label="index+','+cindex">&nbsp;</el-checkbox></td>
											<td></td>
											<td><div class="child-input"><el-input v-model="child.cate_name" maxlength="20" size="mini"></el-input></div></td>
											<td>
												<template v-if="child.image_url">
													<el-popover
														placement="top-start"
														width="200"
														trigger="hover">
														<img :src="child.image_url" @error="imageLoadError" width="100%">
														<a class="table-icon" slot="reference" @click="handleUploadShow(index,cindex)"><i class="el-icon-picture"></i>{{$t('seller.actions.edit')}}</a>
													</el-popover>
													<a class="table-icon" @click="handleImageDel(index,cindex)">{{$t('seller.actions.delete')}}</a>
												</template>
												<template v-else>
													<a class="table-icon" slot="reference" @click="handleUploadShow(index,cindex)"><i class="el-icon-plus"></i>{{$t('seller.actions.addImage')}}</a>
												</template>
											</td>
											<td>
												<a href="javascript:void(0);" class="uu-cate-icon J_CatMoveUp" @click="handleMoveUp(index,cindex)"></a>
												<a href="javascript:void(0);" class="uu-cate-icon J_CatMoveDown" @click="handleMoveDown(index,cindex)"></a>
											</td>
											<td>
												<!-- <el-switch
													v-model="child.default_open"
													:active-value="1"
													:inactive-value="0">
												</el-switch> -->
											</td>
											<td>{{child.create_time | parseTime('{y}-{m}-{d}')}}</td>
											<td>{{child.type==1?$t('seller.decorate.manualCate'):$t('seller.decorate.autoCate')}}</td>
											<td>
												<a href="javascript:void(0);" class="table-btns" @click="handleRemoveCate(index,cindex)">{{$t('seller.actions.delete')}}</a>
											</td>
										</tr>
									</template>
									<tr class="cat-sub">
										<td></td>
										<td></td>
										<td><div class="child-input"><el-button @click="handleAppCate(index)" size="mini"><i class="uu-cate-icon J_AddCat"></i>{{$t('seller.actions.addChildCate')}}</el-button></div></td>
										<td></td>
										<td></td>
										<td></td>
										<td>{{parseInt(Date.now()) | parseTime('{y}-{m}-{d}')}}</td>
										<td></td>
										<td></td>
									</tr>
								</tbody>
							</template>
						</table>
					</el-scrollbar>
				</div>
			</div>
		</div>
		<el-dialog
			:title="$t('seller.dialogTitle.image')"
			class="dialog-upload"
			width="960px"
			:visible.sync="dialogUploadVisible"
			:before-close="handleUploadClose">
			<upload-material
				ref="uploadImage"
				@get-uplod-action="handleUploadAction"
				:upload-type="false">
			</upload-material>
		</el-dialog>
	</div>
</template>

<script>
	import { fetchList, saveCategory } from '@/api/seller/category';
	import uploadMaterial from '@/components/seller/uploadMaterial.vue';
	export default {
		components: {
			uploadMaterial
		},
		data() {
			return {
				activeIndex: this.$route.name,
				menuList: this.$t('seller.decorate.cateMenu'),
				checkAll: false,
				cateList: [],
				checkCate: [],
				addItemIndex: -1,
				addItemChild: -1,
				dialogUploadVisible: false,
				isIndeterminate: false,
			}
		},
		created(){
			this.getCateList();
		},
		methods:{
			imageLoadError(e){
				e.target.src = this.$imageErrorGoods;
			},
			getCateList(){
				fetchList({uuid: this.$store.getters.uuid}).then(response => {
					this.cateList = response.data.list
				})
			},
			handleOpen(e){
				let className = e.target.parentNode.parentNode.parentNode.className;
				if(className=='expand'){
					e.target.className = 'el-icon-caret-right';
					e.target.parentNode.parentNode.parentNode.className = 'folding';
				}else{
					e.target.className = 'el-icon-caret-bottom';
					e.target.parentNode.parentNode.parentNode.className = 'expand';
				}
			},
			handleAppCate(index=-1){
				let timestamp = Date.parse(new Date())/1000;
				let data = {
					cate_id: 0,
					cate_name: '',
					create_time: timestamp,
					default_open: 1,
					image_url: '',
					type: 1,
					if_show: 1,
				};
				if(index>-1){
					data.parent_id = this.cateList[index].cate_id;
					let child = this.cateList[index].children?this.cateList[index].children:[];
					child.push(data);
					this.cateList[index].children = child;
				}else{
					data.parent_id = 0;
					data.children = [];
					this.cateList.push(data);
				}
				this.$forceUpdate();
			},
			handleRemoveCate(index,child=-1){
				if(child>-1){
					if(this.cateList[index].children[child].cate_id>0){
						this.cateList[index].children[child].if_show = 0;
					}else{
						this.cateList[index].children.splice(child,1);
					}
				}else{
					if(this.cateList[index].children && this.cateList[index].children.length>0){
						let flag = false;
						this.cateList[index].children.forEach(child=>{
							if(child.if_show==1){
								flag = true;
							}
						})
						if(flag){
							this.$message.error(this.$t('seller.errorMsg.containCate'));
							return false;
						}
					}
					if(this.cateList[index].cate_id>0){
						this.cateList[index].if_show = 0;
					}else{
						this.cateList.splice(index,1);
					}
				}
				this.$forceUpdate();
			},
			handleUploadShow(index,child=-1){
				this.addItemIndex = index;
				this.addItemChild = child;
				this.dialogUploadVisible = true;
			},
			handleImageDel(index,child=-1){
				if(child>-1){
					this.cateList[index].children[child].image_url = '';
				}else{
					this.cateList[index].image_url = '';
				}
			},
			handleUploadClose(){
				this.addItemIndex = -1;
				this.addItemChild = -1;
				this.dialogUploadVisible = false;
			},
			handleUploadAction(data){
				if(this.addItemChild>-1){
					this.cateList[this.addItemIndex].children[this.addItemChild].image_url = data;
				}else{
					this.cateList[this.addItemIndex].image_url = data;
				}
				this.addItemIndex = -1;
				this.addItemChild = -1;
				this.dialogUploadVisible = false;
			},
			handleSaveCate(){
				let that = this;
				let msgText = '';
				that.cateList.forEach((item,i)=>{
					if(item.if_show == 1 && item.cate_name==''){
						msgText = that.$t('seller.decorate.cateTips1');
					}
					that.cateList.forEach((cate,j)=>{
						if(item.if_show == 1 && cate.if_show == 1 && item.cate_name == cate.cate_name && i!==j){
							msgText = that.$t('seller.decorate.cateTips2');
						}
					})
					let childList = item.children?item.children:[];
					childList.forEach((child,c)=>{
						if(child.cate_name=='' && child.if_show == 1){
							msgText = that.$t('seller.decorate.cateTips3')+item.cate_name+' '+that.$t('seller.decorate.cateTips1');
						}
						childList.forEach((level,l)=>{
							if(child.cate_name == level.cate_name && c!==l && child.if_show == 1 && level.if_show == 1){
								msgText = that.$t('seller.decorate.cateTips3')+item.cate_name+' '+that.$t('seller.decorate.cateTips4');
							}
						})
					})
				})
				if(msgText){
					that.$message.error(msgText);
					return false;
				}
				let loading = that.$loading();
				saveCategory({content: JSON.stringify(that.cateList)}).then(response => {
					that.getCateList();
					loading.close();
					that.$message.success(this.$t('seller.successMsg.save'))
				}).catch(()=>{
					loading.close();
				})
			},
			handleCheckAllChange(val){
				if(val){
					let checkId = []
					this.cateList.forEach((item,index)=>{
						checkId.push(index)
						if(item.children){
							item.children.forEach((child,cindex)=>{
								checkId.push(index+','+cindex)
							})
						}
					})
					this.checkCate = checkId;
				}else{
					this.checkCate = [];
				}
				this.isIndeterminate = false;
			},
			handleChangeCate(val,key){
				let cate = this.cateList[key];
				let cateChild = [];
				if(cate.children){
					cate.children.forEach((child,cindex)=>{
						if(child.if_show==1){
							cateChild.push(key+','+cindex);
						}
					})
					if(val){
						cateChild.forEach(item=>{
							if(!this.checkCate.includes(item)){
								this.checkCate.push(item);
							}
						})
					}else {
						this.checkCate.forEach((item,obj)=>{
							if(cateChild.includes(item)){
								this.checkCate.splice(obj,1);
							}
						})
					}
				}
				let checkCount = this.checkCate.length;
				let cateData = [];
				this.cateList.forEach((item,index)=>{
					if(item.if_show==1){
						cateData.push(index)
						if(item.children){
							item.children.forEach((child,cindex)=>{
								if(child.if_show==1){
									cateData.push(index+','+cindex)
								}
							})
						}
					}
				})
				this.checkAll = checkCount === cateData.length;
				this.isIndeterminate = checkCount > 0 && checkCount < cateData.length;
			},
			handleDeleteCate(){
				let num = 0;
				let cate = [];
				this.cateList.forEach((item,index)=>{
					if(item.if_show==1){
						if(this.checkCate.includes(index) && item.children){
							item.children.forEach((child,cindex)=>{
								if(child.if_show==1 && !this.checkCate.includes(index+','+cindex)){
									num++;
									cate.push(item.cate_name);
								}
							})
						}
					}
				})
				if(num>0){
					let msg = this.$t('seller.decorate.cateTips5')+cate.join(',')+' '+this.$t('seller.decorate.cateTips3');
					this.$alert(msg)
					return false;
				}
				this.checkCate.forEach(item => {
					if(typeof(item)==='string'){
						let keys = item.split(',');
						this.cateList[keys[0]].children[keys[1]].if_show = 0;
					}else{
						this.cateList[item].if_show = 0;
					}
				})
			},
			handleMoveTop(index){
				if(index>0){
					this.cateList.unshift(this.cateList.splice(index,1)[0]);
				}
			},
			handleMoveBtm(index){
				if(index!==this.cateList.length-1){
					this.cateList.push(this.cateList.splice(index,1)[0]);
				}
			},
			handleMoveUp(index,child=-1){
				if(child>0){
					let list = this.cateList[index].children;
					this.cateList[index].children[child]=list.splice(child-1,1,list[child])[0];
				}else if(index>0 && child<0) {
					let list = this.cateList;
					this.cateList[index]=list.splice(index-1,1,list[index])[0];
				}
				this.$forceUpdate();
			},
			handleMoveDown(index,child=-1){
				if(child>-1){
					let list = this.cateList[index].children;
					if(child!==list.length-1){
						this.cateList[index].children[child]=list.splice(child+1,1,list[child])[0];
					}
				}else {
					if(index!==this.cateList.length-1) {
						let list = this.cateList;
						this.cateList[index]=list.splice(index+1,1,list[index])[0];
					}
				}
				this.$forceUpdate();
			},
		},
		watch: {
			'cateList': {
				handler: function(val, oldVal){
					this.$forceUpdate();
				},
				deep: true,
				immediate: true,
			}
		}
	}
</script>

<style lang="scss" scoped>
	.uu-category {
		height: 100%;
		.uu-decorate-menu {
			float: left;
			width: 180px;
			min-width: 180px;
			height: 100%;
			background-color: #ecedf2;
			::v-deep .el-menu {
				margin-top: 10px;
				background-color: transparent;
				.el-menu-item,.el-submenu__title {
					height: 40px;
					line-height: 40px;
					border-left: 3px solid transparent;
					border-bottom: 1px solid #e1e1e1;
				}
				.el-submenu {
					.el-menu-item {
						width: 100%;
						min-width: auto;
					}
				}
				.el-menu-item.is-active,.el-menu-item:hover,.el-submenu__title:hover {
					color: #428ce8;
					border-left-color: #428ce8;
					background-color: transparent;
				}
			}
		}
		.uu-decorate-main {
			margin-left: 180px;
			height: 100%;
			.uu-decorate-toolbar {
				height: 30px;
				line-height: 30px;
				padding: 0 20px;
				border-bottom: 1px solid #b0b0b0;
				background-color: #d4d4d4;
				box-shadow: 0 1px 0 rgba(0,0,0,.1);
				.toolbar-action {
					float: left;
				}
				.toolbar-opts {
					float: right;
					.btn-help {
						color: #666666;
						margin-right: 10px;
						i {
							color: #67C23A;
							font-size: 18px;
							border-radius: 50%;
							vertical-align: middle;
							background-color: #FFFFFF;
							margin-right: 5px;
						}
					}
				}
				::v-deep .el-button {
					padding: 5px 15px;
					border-color: #999999;
					.el-icon-plus {
						font-weight: bold;
					}
				}
				::v-deep .el-button:hover {
					color: #333333;
					background-color: #f3f3f3;
				}
			}
			.uu-decorate-panel {
				height: calc(100% - 31px);
				padding-left: 17px;
				.uu-decorate-panel-head {
					padding-top: 6px;
					height: 30px;
					line-height: 30px;
					.toolbar-opts {
						float: right;
						padding-right: 34px;
						span {
							cursor: pointer;
						}
					}
				}
				.uu-decorate-panel-main {
					height: calc(100% - 36px);
					.table-btns {
						color: $--seller-primary;
						margin-right: 10px;
					}
					.table-btns:last-child {
						margin-right: 0;
					}
				}
				.uu-decorate-panel-table {
					width: 100%;
					th,td {
						line-height: 20px;
						text-align: left;
						padding: 6px 4px 6px 10px;
						box-sizing: border-box;
						border-bottom: 1px solid #ebebeb;
					}
					th {
						background-color: #f4f4f4;
					}
					.child-input {
						padding-left: 20px;
						position: relative;
					}
					.child-input:before {
						content: "";
						top: 9px;
						left: 0;
						width: 9px;
						height: 9px;
						display: block;
						border-left: 1px solid #E1E1E1;
						border-bottom: 1px solid #E1E1E1;
						position: absolute;
					}
					.folding {
						.cat-sub {
							display: none;
						}
					}
					.el-icon-caret-right,.el-icon-caret-bottom {
						font-size: 16px;
						cursor: pointer;
					}
					.table-icon {
						color: #999999;
						cursor: pointer;
						margin-right: 10px;
						i {
							font-size: 16px;
							margin-right: 4px;
						}
					}
					
					.uu-cate-icon {
						width: 20px;
						height: 18px;
						display: inline-block;
						vertical-align: middle;
						background-image: url(~@/assets/images/seller/cate-400-400.gif);
						background-repeat: no-repeat;
					}
					.uu-cate-icon.J_CatMoveTop {
						background-position: 3px 0;
					}
					.uu-cate-icon.J_CatMoveTop:hover {
						background-position: 3px -24px;
					}
					.uu-cate-icon.J_CatMoveBtm {
						background-position: -20px 0;
					}
					.uu-cate-icon.J_CatMoveBtm:hover {
						background-position: -20px -24px;
					}
					.uu-cate-icon.J_CatMoveUp {
						background-position: -41px 0;
					}
					.uu-cate-icon.J_CatMoveUp:hover {
						background-position: -41px -24px;
					}
					.uu-cate-icon.J_CatMoveDown {
						background-position: -64px 0;
					}
					.uu-cate-icon.J_CatMoveDown:hover {
						background-position: -64px -24px;
					}
					.uu-cate-icon.J_EditPic {
						background-position: -247px -98px;
					}
					.uu-cate-icon.J_AddPic {
						background-position: -247px -74px;
					}
					.uu-cate-icon.J_AddCat {
						width: 16px;
						height: 16px;
						background-position: -388px -54px;
					}
				}
			}
		}
	}
</style>
